import { Routes, Route, Link } from "react-router-dom";
import Home from "./Home";
import About from "./About";

export default function Layout() {
  return (
    <>
      <h1>React App Layout!</h1>

      <ul>
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <Link to="/about">About</Link>
        </li>
      </ul>

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/about" element={<About />} />
      </Routes>
    </>
  );
}
